<template>
  <v-app-bar id="app-bar" absolute app color="transparent" flat height="75">
    <v-alert
      :value="alertShow"
      :color="alertType"
      dark
      border="top"
      transition="slide-y-transition"
      style="
        position: absolute;
        z-index: 100;
        width: 400px;
        right: 10px;
        top: 10px;
      "
    >
      <div class="pt-6 pr-6">
        <v-row>
          <v-spacer></v-spacer>
          <v-btn :color="alertType" fab x-small @click="setAlertShow(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>

        <p>
          {{ alertMsg }}
        </p>
      </div>
    </v-alert>
    <v-btn class="mr-3" elevation="8" fab small @click="setDrawer(!drawer)">
      <v-icon v-if="!mobile" class="actionButton--text">
        mdi-dots-vertical
      </v-icon>

      <v-icon v-else class="actionButton--text"> mdi-menu </v-icon>
    </v-btn>

    <v-spacer />
    <!-- <v-btn @click="setAlertShow(true)">Test</v-btn> -->

    <v-btn class="ml-2" min-width="0" text @click="logout">
      <v-icon class="actionButton--text">mdi-logout</v-icon> Sair
    </v-btn>
  </v-app-bar>
</template>

<script>
// Components
import { VHover, VListItem } from "vuetify/lib";

// Utilities
import { mapState, mapMutations } from "vuex";
import corsolarApi from "../../../../libraries/corsolar/corsolarApi";

export default {
  name: "DashboardCoreAppBar",

  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    "black--text": !hover,
                    "white--text secondary elevation-12": hover,
                  },
                  props: {
                    activeClass: "",
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                },
                this.$slots.default
              );
            },
          },
        });
      },
    },
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    notifications: [],
  }),

  computed: {
    ...mapState(["drawer", "alertMsg", "alertShow", "alertType"]),
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },

  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER",
      setAlertShow: "SET_ALERT_SHOW",
    }),
    async logout() {
      await corsolarApi.userLogs.verifyIfExistLog(this.user, "/logout");
      //this.$root.$emit("Spinner::show");
      await this.$firebase.auth().signOut();
      this.$store.commit("CLEAR_USER");
      this.$store.commit("CLEAR_TOKEN");
      this.$store.commit("CLEAR_ACCESS");
      // this.$session.clear();
      this.$router.push("/login");
    },
  },
};
</script>
